var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "header-dots menusearch-div" }, [
    _c("div", { staticClass: "horizon-full-menu-area2" }, [
      _c(
        "div",
        { staticClass: "row items-center top-menu-item-group2-head2" },
        [
          _c(
            "q-btn-group",
            { staticClass: "top-menu-item-group2" },
            _vm._l(_vm.permission_routers, function (topMenu, idx) {
              return _c("q-btn", {
                key: idx,
                attrs: {
                  icon: topMenu.icon,
                  flat: _vm.activeMenu == topMenu.name ? false : true,
                  unelevated: _vm.activeMenu == topMenu.name ? true : false,
                  color: "blue-custom",
                  size: "13px",
                  stack: "",
                  label: topMenu.title,
                },
                on: {
                  mouseover: function ($event) {
                    return _vm.linkClick(topMenu, true)
                  },
                },
              })
            }),
            1
          ),
        ],
        1
      ),
      _vm.secondMenus && _vm.secondMenus.length > 0
        ? _c(
            "ul",
            { staticClass: "under-menu-list2" },
            _vm._l(_vm.secondMenus, function (sceondMenu, idx2) {
              return _c("li", { key: idx2, staticClass: "under-menu-item2" }, [
                _c(
                  "div",
                  {
                    class: [
                      "sceond-menu2",
                      !sceondMenu.child
                        ? "sceond-menu2-nochild cursor-pointer"
                        : "",
                    ],
                    on: {
                      click: function ($event) {
                        return _vm.linkClick(sceondMenu, false)
                      },
                    },
                  },
                  [
                    _c("i", { staticClass: "material-icons" }, [
                      _vm._v("arrow_right"),
                    ]),
                    _vm._v(" " + _vm._s(sceondMenu.title) + " "),
                  ]
                ),
                _c(
                  "div",
                  { staticClass: "third-menu" },
                  _vm._l(sceondMenu.child, function (thirdMenu, idx3) {
                    return _c(
                      "div",
                      { key: idx3, staticClass: "third-menu-item2" },
                      [
                        _c(
                          "i",
                          { staticClass: "material-icons under-muenu-title2" },
                          [_vm._v("arrow_right")]
                        ),
                        !thirdMenu.child || thirdMenu.child.length <= 0
                          ? _c(
                              "a",
                              {
                                staticClass: "third-menu-button cursor-pointer",
                                on: {
                                  click: function ($event) {
                                    return _vm.linkClick(thirdMenu, false)
                                  },
                                },
                              },
                              [_vm._v(" " + _vm._s(thirdMenu.title) + " ")]
                            )
                          : _c("span", [
                              _vm._v(" " + _vm._s(thirdMenu.title) + " "),
                              _c(
                                "div",
                                { staticClass: "third-menu-pop" },
                                _vm._l(
                                  thirdMenu.child,
                                  function (lastMenu, idx4) {
                                    return _c("div", { key: idx4 }, [
                                      _c(
                                        "a",
                                        {
                                          staticClass:
                                            "third-menu-button cursor-pointer",
                                          on: {
                                            click: function ($event) {
                                              return _vm.linkClick(
                                                lastMenu,
                                                false
                                              )
                                            },
                                          },
                                        },
                                        [
                                          _c(
                                            "i",
                                            {
                                              staticClass:
                                                "material-icons under-muenu-title2",
                                            },
                                            [_vm._v("arrow_right")]
                                          ),
                                          _vm._v(
                                            " " + _vm._s(lastMenu.title) + " "
                                          ),
                                        ]
                                      ),
                                    ])
                                  }
                                ),
                                0
                              ),
                            ]),
                      ]
                    )
                  }),
                  0
                ),
              ])
            }),
            0
          )
        : _vm._e(),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }