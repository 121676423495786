<template>
  <div class="header-dots menusearch-div">
      <div class="horizon-full-menu-area2">
        <div class="row items-center top-menu-item-group2-head2">
          <q-btn-group class="top-menu-item-group2">
            <q-btn 
              v-for="(topMenu, idx) in permission_routers" 
              :key="idx"
              :icon="topMenu.icon"
              :flat="activeMenu == topMenu.name ? false : true"
              :unelevated="activeMenu == topMenu.name ? true : false"
              color="blue-custom"
              size="13px"
              stack
              :label="topMenu.title"
              @mouseover="linkClick(topMenu, true)">
            </q-btn>
          </q-btn-group>
        </div>
        <ul v-if="secondMenus && secondMenus.length > 0" class="under-menu-list2">
          <li
            v-for="(sceondMenu, idx2) in secondMenus"
            :key="idx2"
            class="under-menu-item2">
            <div :class="['sceond-menu2', (!sceondMenu.child ? 'sceond-menu2-nochild cursor-pointer' : '')]" @click="linkClick(sceondMenu, false)"><i class="material-icons">arrow_right</i> {{ sceondMenu.title }}
            </div>
            <div class="third-menu">
              <div v-for="(thirdMenu, idx3) in sceondMenu.child"
                :key="idx3"
                class="third-menu-item2">
                <i class="material-icons under-muenu-title2">arrow_right</i>
                <a v-if="!thirdMenu.child || thirdMenu.child.length <= 0"
                  class="third-menu-button cursor-pointer" 
                  @click="linkClick(thirdMenu, false)">
                  {{thirdMenu.title}}
                </a>
                <span v-else>
                  {{thirdMenu.title}}
                  <!--  <i class="material-icons under-muenu-title2">add_circle</i> -->
                  <div class="third-menu-pop">
                    <div 
                      v-for="(lastMenu, idx4) in thirdMenu.child" 
                      :key="idx4" 
                    >
                      <a 
                        class="third-menu-button cursor-pointer" 
                        @click="linkClick(lastMenu, false)">
                        <i class="material-icons under-muenu-title2">arrow_right</i> 
                        {{lastMenu.title}}
                      </a>
                    </div>
                  </div>
                </span>
              </div>
            </div>
          </li>
        </ul>
      </div>
  </div>
</template>

<script>
import { library } from "@fortawesome/fontawesome-svg-core";
import { faEllipsisV } from "@fortawesome/free-solid-svg-icons";
import { mapGetters } from 'vuex'


library.add(faEllipsisV);
export default {
  name: "SiteMap",
  components: {
    // "font-awesome-icon": FontAwesomeIcon
  },

  computed: {
    ...mapGetters([
      'permission_routers',
    ]),
    secondMenus() {
      let secondMenus = [];
      if (this.permission_routers && this.permission_routers.length > 0) {
        let selectMenu = this.$_.find(this.permission_routers, { name: this.activeMenu });
        if (selectMenu && selectMenu.child && selectMenu.child.length > 0) {
          secondMenus = selectMenu.child
        }
      }
      return secondMenus;
    },
  },
  data() {
    return {
      isTopMenuOpen: false,
      isOpen: false,
      isOpenMobileMenu: false,
      activeMenu: '',
      showingthird: false,
      dropdownMenuFullClass: 'dropdown-menu-full2',
    };
  },
  props: {
    headerbg: String
  },
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
    if (this.permission_routers && this.permission_routers.length > 0) {
      this.activeMenu = this.$_.clone(this.permission_routers[0].name);
    }
  },
  methods: {
    toggleMenu() {
      this.isTopMenuOpen = !this.isTopMenuOpen;
    },
    toggleBodyClass(className) {
      const el = document.body;
      this.isOpen = !this.isOpen;

      if (this.isOpen) {
        el.classList.add(className);
      } else {
        el.classList.remove(className);
      }
    },
    toggleMobile(className) {
      const el = document.body;
      this.isOpen = !this.isOpen;

      if (this.isOpen) {
        el.classList.add(className);
      } else {
        el.classList.remove(className);
      }
    },
    toggleMobile2(className) {
      const el = document.body;
      this.isOpenMobileMenu = !this.isOpenMobileMenu;

      if (this.isOpenMobileMenu) {
        el.classList.add(className);
      } else {
        el.classList.remove(className);
      }
    },
    linkClick(_link, isTop) {
      if (isTop) {
        this.activeMenu = _link.name
      }
      if (_link.href) {
        this.$comm.movePage(this.$router, _link.href)
        var _selectedMenu = document.getElementsByClassName('dropdown-menu-full2')[0];
        if (_selectedMenu) {
          _selectedMenu.classList.remove('show');
        }
      }
    },
    hideLastMenuPop(thirdMenu) {
      this.$set(thirdMenu, 'menuOpen', null)
    },
    siteMapOpen() {
      if (document.getElementsByClassName('closed-sidebar').length > 0) {
        this.dropdownMenuFullClass = 'dropdown-menu-full2-mini';
      } else {
        this.dropdownMenuFullClass = 'dropdown-menu-full2';
      }
    }
  }
};
</script>
<style lang="sass">

.break
  flex-basis: 100%
  height: 0
.top-menu-item-group2-head2
  padding: 6px 16px 16px 16px
.top-menu-item-group2
  background: #fff
  width: 100%
  padding: 0
  justify-content: center
  button
    margin-right: 0px !important

.horizon-full-menu-area2
  margin: 0 auto
  z-index: 999
  background: #fff
  .horizon-full-menu-items
    justify-content: center
  button
    padding: 5px 10px 0px 10px !important
  .under-menu-list2
    column-count: 5
    padding: 10px !important
  .under-menu-item2
    flex-wrap: inherit !important
    margin: 0
    display: grid
    grid-template-rows: 1fr auto
    margin-bottom: 10px
    break-inside: avoid
  .sceond-menu2
    width: 100%
    text-align: left
    padding-left: 20px
    height: 36px
    line-height: 34px 
    // border: 1px solid #0072c6
    background: #f47725
    border-radius: 16px
    color: #fff
  .sceond-menu2-nochild
    border: 1px solid #f47725 !important
    background: #fff !important
    color: #f47725 !important
  .third-menu
    width: 100%
    padding: 14px 10px 14px 14px
    .third-menu-item2
      padding-bottom: 6px
  .third-menu-button:hover
    color: #0072c6 !important
    text-decoration: underline !important
.last-menu-area
  padding: 0 10px 0 0 !important
  min-height: 30px !important
.dropdown-menu-full2
  width: calc(99vw - 230px) !important
  min-height: 200px !important
  height: auto !important
  z-index: 22 !important
  background: #F1F6F9
  top: 8px !important
  right: 30px
.dropdown-menu-full2-mini
  width: calc(99vw - 50px) !important
  min-height: 200px !important
  height: auto !important
  z-index: 22 !important
  background: #F1F6F9
  top: 8px !important
  right: 30px
.third-menu-pop
  position: relative
  top: 5px
  left: 30px
</style>